import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import axios from "axios"
import VueHighlightJS from "vue-highlightjs"
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo)

Vue.config.productionTip = false

Vue.use(VueHighlightJS)

new Vue({
    router,
    render: h => h(App),
    data: {
      message: "Get Twitter user's timeline with VueJS and Axios",
      tweets: null,
      showMobileMenu: false
    },
    methods: {
      twitterFeed: function() {
        var _this = this;
        axios.get('https://api.twitter.com/1.1/statuses/user_timeline.json', {
          params: {
            screen_name: "patrickmeuni"
          }
        })
          .then(function (response) {
            _this.tweets = response.data;
        })
          .catch(function (error) {
            console.log(error);
        });
      },
      myFilter: function() {
        this.isActive = !this.isActive;
        // some code to filter users
      }
    }
}).$mount("#app")