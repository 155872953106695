<template>
    <span :style="'font-size: ' + this.size">
        <span style="font-size: 01.2em; vertical-align:">Flight Software Workshop</span>
    </span>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: "1em"
        }
    }
}
</script>
