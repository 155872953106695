<template>
    <div class="home">
        <Home :workshop="workshop" />
    </div>
</template>

<script>
import Home from '@/components/Home.vue';
import workshops from '@/workshops.js';

export default {
    name: 'home',
    components: {
        Home
    },
    data: function() {
        return {
            workshop: workshops[Object.keys(workshops)[0]]
        };
    }
}
</script>
