const events = [
    {
        title: "FREE Admissions and Workshop Dates Update!",
        venue: "September 8th, 2020, Laurel MD",
        contents: [
            "We are excited to announce that as a result of our decision to go Virtual for the 2021 FSW Workshop, we will be hosting the workshop from February 8th to the 11th of 2021 FREE of charge! \
            Seeing as we will not be needing a venue and have no other costs associated with an in-person workshop, this year the presentations will be brought to you without cost. \
            Please help us make this year's workshop even better by recommending 2021 Workshop Themes, you can find the form under the 2021 Workshop page.\
            We look forward to virtually seeing you there!",        ]
    },
    // {
    //     title: "Virtual FSW Workshop 2021",
    //     venue: "July 26th, 2020, Laurel MD",
    //     contents: [
    //         "In light of recent events, the FSW Workshop 2021 Event has gone Virtual! To ensure the safety of our staff and attendees we have decided to make next year's workshop a virtual event. \
    //         As we go forth please be on the look out for more information on the event and an upcoming 'Save-the-date' email. Thank you for your understanding. Looking forward \
    //         to seeing you at the 2021 FSW Workshop!",        ]
    // },
    // {
    //     title: "FSW Workshop 2021 Themes Questionnaire",
    //     venue: "June 8th, 2020, Laurel MD",
    //     contents: [
    //         `Help us by recommending one or more Workshop themes!  This year, we are opening up the themes decision to the community and asking you to let us know whichthe topics you are most interested in.  Themes help tailor the call for abstracts, hone the selection process, and make the year's Workshop one of a kind. If you have an idea, please share it with us via this Google Form!`
    //     ]
    // },
    // {
    //     title: "FSW Workshop 2021",
    //     venue: "Summer 2021, Laurel MD",
    //     contents: [
    //         `Come join us at the Johns Hopkins University Applied Physics Laboratory.`, `We are excited to announce that this year we will be co-located with the Space Computing Conference!` 
    //     ]
    // },
];

export default events;