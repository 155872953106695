<template>
    <div id="app">
        <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <router-link class="navbar-item" to="/">
                FSW Home
            </router-link>
            <router-link class="navbar-item" to="/archive">
                Workshops
            </router-link>
        </div>
        <div id="navMenu" class="navbar-menu">
            <div class="navbar-end">
                <router-link class="navbar-item" to="/talks">
                    Talks
                </router-link>
                <router-link to="/about" class="navbar-item">
                    About Us
                </router-link>
                <router-link to="/sponsors" class="navbar-item">
                    Sponsors
                </router-link>
                <!-- <router-link to="/covid19" class="navbar-item">
                    COVID-19 News
                </router-link> -->
            </div>
        </div>
        </nav>

        <!-- <div class="notification is-danger" style="width: auto; text-align: center; height: auto; padding: 0.4%; margin: 0%">
            Abstract Submission Deadline Extended to 12/03/2021 for the
            <router-link to="/workshop/FSW2022">
                 2022 Virtual FSW Workshop!
            </router-link>
            <button class="delete"></button>
        </div> -->

        <router-view />

        <section class="hero is-dark">
            <div class="hero-body">
                <div class = "columns">
                    <div class="container">
                        <small class=" is-pulled-left has-text-right">
                            <div class="control">
                                <a
                                    href="https://www.youtube.com/channel/UCNUuEywaLBmwgOLRXhFPLPw"
                                    class="button is-medium is-danger"
                                >
                                    <span class="icon is-small">
                                        <i class="fab fa-youtube"></i>
                                    </span>
                                </a>
                                <a
                                    href="https://twitter.com/FSWWorkshop"
                                    class="button is-medium is-info is-light"
                                >
                                    <span class="icon is-small os">
                                        <i class="fab fa-twitter"></i>
                                    </span>
                                </a>
                                <a
                                    href="https://www.linkedin.com/groups/1072247/"
                                    class="button is-medium is-info"
                                >
                                    <span class="icon is-small">
                                        <i class="fab fa-linkedin"></i>
                                    </span>
                                </a>
                            </div>
                        </small>
                        <small class="is-pulled-right has-text-right">
                            <router-link to="/archive">
                                Workshops
                            </router-link> |
                            <router-link to="/talks">
                                Talks
                            </router-link> |
                            <router-link to="/about" >
                                About Us
                            </router-link> |
                            <router-link to="/sponsors">
                                Sponsors
                            </router-link>
                            <br/>
                            <a href="https://www.jhuapl.edu/Home/Privacy"
                                >Privacy Policy</a
                            >
                            | <a href="https://www.jhuapl.edu">APL Home</a> <br />
                            &copy; {{ new Date().getFullYear() }} JHU|APL. All rights reserved.
                        </small>
                        <br/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import workshops from "@/workshops.js";
import _ from "lodash";

export default {
    data() {
        return {
            workshops: _.sortBy(_.filter(workshops, "public"), i => i.date * -1)
        };
    }
};
document.addEventListener('DOMContentLoaded', () => {
  (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    var $notification = $delete.parentNode;

    $delete.addEventListener('click', () => {
      $notification.parentNode.removeChild($notification);
    });
  });
});
</script>

<style lang="scss">
$blue: darken(white, 15);
$navbar-item-color: white;
$navbar-dropdown-background-color: darkgray;
@import "~bulma/bulma.sass";
::selection {
    background: #black;
}
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
