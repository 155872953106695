import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

let router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            meta: {
                title: "Home"
            },
            component: Home
        },
        {
            path: "/archive",
            name: "Archive",
            props: true,
            meta: {
                title: "Archive"
            },
            component: () =>
                import(/* webpackChunkName: "archive" */ "./views/Archive.vue")
        },
        {
            path: "/workshop/:workshopId",
            name: "Workshop",
            props: true,
            meta: {
                title: "Workshop"
            },
            component: () =>
                import(
                    /* webpackChunkName: "workshop" */ "./views/Workshop.vue"
                )
        },
        {
            path: "/talks",
            name: "talks",
            meta: {
                title: "Talks"
            },
            component: () =>
                import(/* webpackChunkName: "workshop" */ "./views/Talks.vue")
        },
        {
            path: "/about",
            name: "about",
            meta: {
                title: "About Us"
            },
            component: () =>
                import(/* webpackChunkName: "workshop" */ "./views/About.vue")
        },
        {
            path: "/covid19",
            name: "covid19",
            meta: {
                title: "Covid"
            },
            component: () =>
                import(/* webpackChunkName: "covid" */ "./views/Covid.vue")
        },
        {
            path: "/forms",
            name: "forms",
            meta: {
                title: "Forms"
            },
            component: () =>
                import(/* webpackChunkName: "covid" */ "./views/Forms.vue")
        },
        {
            path: "/sponsors",
            name: "sponsors",
            meta: {
                title: "Sponsors"
            },
            component: () =>
                import(
                    /* webpackChunkName: "sponsors" */ "./views/Sponsors.vue"
                )
        }
    ]
});

router.afterEach(next => {
    document.title = next.meta.title + " | Flight Software Workshop";
    return next;
});

export default router;
